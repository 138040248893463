import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import SettingsIcon from "@mui/icons-material/Settings";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import RadioIcon from "@mui/icons-material/Radio";
import DvrIcon from "@mui/icons-material/Dvr";
import MicIcon from "@mui/icons-material/Mic";
import CableIcon from "@mui/icons-material/Cable";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";

const Step3EstadoComentarios = ({
  comentario,
  setComentario,
  status,
  setStatus,
  handleNext,
  handleBack,
  generalState,
  setGeneralState,
  fusibles,
  setFusibles,
  inspectionId, // UID de la inspección
}) => {
  const handleCheckboxChange = (field, value) => {
    setGeneralState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleFusibleChange = (value) => {
    setFusibles((prev) => [...prev, value]);
  };

  const handleRemoveFusible = (index) => {
    setFusibles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleNextStep = async () => {
    if (inspectionId) {
      try {
        await updateDoc(doc(db, "inspecciones", inspectionId), {
          Comentario: comentario,
          Status: status,
          EstadoGeneral: generalState,
          FusiblesReemplazados: fusibles,
        });
        handleNext();
      } catch (error) {
        console.error("Error al actualizar la inspección:", error);
      }
    }
  };

  const replacements = [
    { label: "Fusible 10 AMP", icon: <ElectricBoltIcon /> },
    { label: "Fusible 30 AMP", icon: <ElectricBoltIcon /> },
    { label: "Antena", icon: <SettingsIcon /> },
    { label: "Diafragma", icon: <SettingsIcon /> },
    { label: "Control Torreta", icon: <SettingsIcon /> },
    { label: "Porta Fusibles", icon: <ElectricBoltIcon /> },
    { label: "Modulo Torreta", icon: <SettingsIcon /> },
    { label: "Control Sirena", icon: <SettingsIcon /> },
    { label: "Cerebro Sirena", icon: <SettingsIcon /> },
    { label: "Acoplador Sirena", icon: <SettingsIcon /> },
    { label: "Microfono Radio", icon: <MicIcon /> },
    { label: "Radio", icon: <RadioIcon /> },
    { label: "DVR", icon: <DvrIcon /> },
    { label: "Camara Frontal", icon: <CameraAltIcon /> },
    { label: "Camara Interior", icon: <CameraAltIcon /> },
    { label: "Camara Trasera", icon: <CameraAltIcon /> },
    { label: "Cableado", icon: <CableIcon /> },
  ];

  const isReplacementSelected = (label) => {
    return fusibles.includes(label);
  };

  const handleReplacementClick = (label) => {
    if (label.includes("Fusible")) {
      handleFusibleChange(label);
    } else if (!isReplacementSelected(label)) {
      setFusibles((prev) => [...prev, label]);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Comentario"
            variant="outlined"
            multiline
            rows={4}
            value={comentario}
            onChange={(e) => setComentario(e.target.value)}
            sx={{
              ".MuiOutlinedInput-root": {
                color: "#ffffff",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#00ffff",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00ffff",
              },
              label: {
                color: "#00ffff",
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel sx={{ color: "#00ffff" }}>Status</InputLabel>
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              label="Status"
              sx={{
                color: "#ffffff",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#00ffff",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#00ffff",
                },
                ".MuiSvgIcon-root ": {
                  fill: "#00ffff !important",
                },
              }}
            >
              <MenuItem value="Correcto">Correcto</MenuItem>
              <MenuItem value="Incompleto">Incompleto</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" sx={{ color: "#00ffff", mb: 2 }}>
          Estado General
        </Typography>
        <Grid container spacing={2}>
          {[
            { label: "Bocina", field: "Bocina" },
            { label: "Radio", field: "Radio" },
            { label: "Torreta", field: "Torreta" },
            { label: "DVR", field: "DVR" },
          ].map(({ label, field }) => (
            <Grid item xs={6} key={field}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() =>
                  handleCheckboxChange(
                    field,
                    generalState[field] === "Correcto"
                      ? "Incorrecto"
                      : "Correcto"
                  )
                }
                sx={{
                  borderColor:
                    generalState[field] === "Correcto" ? "#00C49F" : "#ff1744",
                  color:
                    generalState[field] === "Correcto" ? "#00C49F" : "#ff1744",
                  "&:hover": {
                    borderColor:
                      generalState[field] === "Correcto"
                        ? "#00C49F"
                        : "#ff1744",
                    backgroundColor: "transparent",
                  },
                }}
              >
                {label} ({generalState[field]})
              </Button>
            </Grid>
          ))}
        </Grid>

        <Typography variant="h6" sx={{ color: "#00ffff", mb: 2, mt: 4 }}>
          Reemplazos
        </Typography>
        <Grid container spacing={2}>
          {replacements.map(({ label, icon }) => (
            <Grid item xs={6} key={label}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => handleReplacementClick(label)}
                sx={{
                  borderColor: isReplacementSelected(label)
                    ? "#00C49F"
                    : "#00ffff",
                  color: isReplacementSelected(label) ? "#00C49F" : "#00ffff",
                  "&:hover": {
                    borderColor: isReplacementSelected(label)
                      ? "#00C49F"
                      : "#00ffff",
                    backgroundColor: "transparent",
                  },
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {React.cloneElement(icon, {
                  sx: {
                    color: isReplacementSelected(label) ? "#00C49F" : "#00ffff",
                  },
                })}
                <Typography
                  sx={{
                    ml: 1,
                    color: isReplacementSelected(label) ? "#00C49F" : "#00ffff",
                    fontWeight: "bold",
                  }}
                >
                  {label}
                </Typography>
              </Button>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" sx={{ color: "#00ffff", mb: 2 }}>
            Reemplazos Seleccionados
          </Typography>
          {fusibles.map((f, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 1,
                border: "1px solid #00C49F",
                borderRadius: 2,
                p: 1,
              }}
            >
              <Typography sx={{ color: "#ffffff", flexGrow: 1 }}>
                {f}
              </Typography>
              <IconButton
                onClick={() => handleRemoveFusible(index)}
                sx={{ color: "#ff1744" }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleBack}
          sx={{ mr: 2 }}
        >
          Anterior
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNextStep} // Actualiza la información en Firestore y avanza
          sx={{
            backgroundColor: "#00C49F",
            color: "#000000",
            "&:hover": {
              backgroundColor: "#009688",
            },
          }}
        >
          Siguiente
        </Button>
      </Box>
    </>
  );
};

export default Step3EstadoComentarios;
